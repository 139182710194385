<template>
  <div class="mortality-results-settings-page">
    <div class="txt-16 txt-font-din-medium settings-page-header mt-20 ml-4">
      {{ $t('components.titles.dataEntrySettings') }}
    </div>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black mb-10">{{ $t('components.labels.autopopulation') }}</div>
      <p>{{ $t('components.description.selectAutoFill') }}</p>
      <a-checkbox-group v-model="settings.autopopulation" :disabled="isSupervisor">
        <a-row class="mb-8 w350">
          <a-col :span="12">
            <a-checkbox value="survDate"> {{ $t('components.dropdown.surveyDate') }}</a-checkbox>
          </a-col>
          <a-col :span="12">
            <a-checkbox value="cluster"> {{ $tc('components.dropdown.cluster', 1) }} </a-checkbox>
          </a-col>
        </a-row>
        <a-row class="mb-8 w350">
          <a-col :span="12">
            <a-checkbox value="team"> {{ $t('components.dropdown.teamNumber') }} </a-checkbox>
          </a-col>
          <a-col :span="12">
            <a-checkbox value="hh"> {{ $t('components.dropdown.householdNumber') }} </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black mb-10">
        {{ $t('components.titles.causeOfDeath') }}
        <a-tooltip :title="$t('components.toolTips.causeOfDeath')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <a-checkbox v-model="settings.showCauseOfDeath" :disabled="isSupervisor">
        {{ $t('components.dropdown.showColumnOnTable') }}
      </a-checkbox>
      <div class="flex flex-wrap w635">
        <div
          v-for="(item, index) in settings.causeOfDeath"
          :key="index"
          class="mr-10 pt-10 pb-10 flex align-center">
          <div v-if="item.code != null">
            <label class="mb-0 mr-10">{{ item.label }}</label>
            <a-input
              :value="item.code"
              class="mr-20 w60"
              size="large"
              :disabled="true"
              :editable="false"/>
          </div>
          <!-- TODO: REMOVE if not dealing with old cause of death settings -->
          <div v-else :hidden="index !== 0" class="txt-16 txt-red txt-bold">{{ $t('components.description.pleaseResetSettings') }}</div>
        </div>
      </div>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black mb-10">
        {{ $t('components.titles.locationOfDeath') }}
        <a-tooltip :title="$t('components.toolTips.locationOfDeath')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <a-checkbox v-model="settings.showLocationOfDeath" :disabled="isSupervisor">
        {{ $t('components.dropdown.showColumnOnTable') }}
      </a-checkbox>
      <div class="flex flex-wrap w635">
        <div
          v-for="(item, index) in settings.locationOfDeath"
          :key="index"
          class="w300 mt-8 mb-8">
          <div v-if="'code' in item || item.custom === true" class="flex align-center mr-20">
            <a-input
              v-model="settings.locationOfDeath[index].label"
              :disabled="isSupervisor"
              :class="['w200 mr-4', item.label !== null && item.label !== undefined && item.label !== '' ? null : 'missing-value']"
              size="large"
              :placeholder="settings.locationOfDeath[index].code != null ? $t('components.description.labelFor', { label: settings.locationOfDeath[index].code}) : $t('components.description.locationLabel')"
              :editable="false"/>
            <div class="ml-4 mr-4">-</div>
            <a-input-number
              v-model="settings.locationOfDeath[index].code"
              :class="['w60 ml-4', item.code !== null && item.code !== undefined ? null : 'missing-value']"
              size="large"
              :disabled="isSupervisor"
              :editable="false"/>
            <a-button
              v-if="index !== 0"
              class="ml-8"
              type="danger"
              shape="circle"
              icon="delete"
              :disabled="isSupervisor"
              @click="deleteLocationOfDeath(index)"/>
          </div>
          <!-- TODO: REMOVE if not dealing with old cause of death settings -->
          <div v-else :hidden="index !== 0" class="txt-16 txt-red txt-bold">{{ $t('components.description.pleaseResetSettings') }}</div>
        </div>
      </div>
      <a-button
        type="link"
        class="
          txt-16
          mt-20
          txt-black txt-semi-bold txt-font-din-medium
          letter-spacing
          flex
          align-center
        "
        :disabled="isSupervisor"
        @click="addLocationOfDeath">
        <a-icon
          type="plus-circle"
          theme="filled"
          class="mr-4"
          :class="{ 'txt-orange': !isSupervisor }"
          style="font-size: 20px"/>
        {{ $t('components.description.addNewLocationOfDeath') }}
      </a-button>
    </a-card>
    <div class="txt-16 txt-font-din-medium settings-page-header mt-40">{{ $t('components.titles.resultSettings') }}</div>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black mb-10">
        {{ $t('components.labels.ageGroupsForEvaluation') }}
        <a-tooltip :title="$t('components.toolTips.ageGroupsForEvaluation')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <a-radio-group v-model="settings.ageGroup" :disabled="isSupervisor" name="age-group" class="w635">
        <a-row class="mt-20">
          <a-col :span="9">
            <a-radio value="five" class="mb-10">
              {{ $t('components.dropdown.fiveYearFixed') }}
            </a-radio>
            <a-radio value="ten">
              {{ $t('components.dropdown.tenYearFixed') }}
            </a-radio>
          </a-col>
          <a-col :span="13">
            <a-radio value="flexible">
              {{ $t('components.dropdown.flexibleIntervals') }}
            </a-radio>
            <div class="flex flex-wrap w100 age-group-line-between">
              <div
                v-for="(value, index) in settings.ageGroupFlexible" :key="index"
                class="flex align-center mt-8 mb-8">
                <a-input-number v-model="settings.ageGroupFlexible[index].start" :disabled="isSupervisor"
                  :min="index > 0 && settings.ageGroupFlexible[index -1].end != null ? settings.ageGroupFlexible[index -1].end : 0"
                  :max="settings.ageGroupFlexible[index].end"
                  size="large" :class="['w60', settings.ageGroupFlexible[index].start !== null ? null : 'missing-value']"/>
                <div class="ml-4 mr-4">-</div>
                <a-input-number v-model="settings.ageGroupFlexible[index].end"
                  :min="index < settings.ageGroupFlexible.length - 1 ? settings.ageGroupFlexible[index + 1].start - 1 :
                      index == settings.ageGroupFlexible.length - 1 ? settings.ageGroupFlexible[index].start : 0"
                  :max="index < settings.ageGroupFlexible.length - 1 && settings.ageGroupFlexible[index + 1].start ? settings.ageGroupFlexible[index + 1].start - 1 : undefined"
                  :disabled="index < settings.ageGroupFlexible.length - 1 || isSupervisor"
                  size="large" :class="['w60 mr-32', settings.ageGroupFlexible[index].end !== null ? null : 'missing-value']"/>
              </div>
            </div>
            <div class="txt-grey txt-italic">
              {{ $t('components.description.disaggregationSmallGroupsResults') }}
            </div>
          </a-col>
        </a-row>
      </a-radio-group>
    </a-card>
    <div class="mt-40 mb-40">
      <a-button :disabled="isSupervisor" type="primary" size="large" @click="save">
        {{ $t('components.description.saveSettings') }}
      </a-button>
      <a-popconfirm
        :title="$t('components.notifications.resetYourSettings')"
        :ok-text="$t('values.yes')"
        :cancel-text="$t('values.no')"
        :disabled="isSupervisor"
        @confirm="reset">
        <a-button :disabled="isSupervisor" class="ml-20" type="danger" size="large">
          {{ $t('components.description.resetSettings') }}
        </a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'MortalitySettings',
  data() {
    return {
      settings: { ...this.$store.state.results.mortalitySettings }
    };
  },
  computed: {
    ...mapState({
      mortalitySettings: (state) => state.results.mortalitySettings,
      surveyId: (state) => state.survey.surveyId,
      projectId: (state) => state.survey.project.id,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    mortalitySettings(settings) {
      this.settings = isEmpty(settings) ? this.settings : { ...settings };
    }
  },
  mounted() {
    this.settings = isEmpty(this.mortalitySettings)
      ? this.settings
      : this.mortalitySettings;
  },
  methods: {
    ...mapActions(['saveMortalitySettings']),
    addLocationOfDeath() {
      this.settings.locationOfDeath.push({
        label: undefined,
        code: undefined,
        custom: true
      });
    },
    deleteLocationOfDeath(index) {
      this.settings.locationOfDeath = this.settings.locationOfDeath.filter(
        (_, i) => i !== index
      );
    },
    isVariableRangeValid() {
      return this.settings.ageGroupFlexible.every(item => item.start !== null && item.end !== null);
    },
    isLocationOfDeathValid() {
      return this.settings.locationOfDeath.every(item => (item.label || item.label === 0) && (item.code || item.code === 0));
    },
    formIsValid() {
      return this.isLocationOfDeathValid() && this.isVariableRangeValid();
    },
    async save() {
      if (!this.formIsValid()) {
        this.$alert().danger(this.$t('components.notifications.ensureAllFieldsComplete'));
        return;
      }
      await this.$message.loading({ content: this.$t('components.notifications.savingSettings'), duration: 1 });
      this.saveMortalitySettings({
        surveyId: this.surveyId,
        projectId: this.projectId,
        settings: this.settings
      });
      this.$alert().success(this.$t('components.notifications.savedSettings'));
    },
    reset() {
      this.settings = { ...this.$store.state.results.defaultMortalitySettings };
      this.saveMortalitySettings({
        surveyId: this.surveyId,
        projectId: this.projectId,
        settings: this.settings
      });
      this.$alert().success(this.$t('components.notifications.resetSettings'));
    }
  }
};
</script>

<style lang="scss" scoped>
.missing-value {
  border:#FF0000 1px solid;
}

.settings-page-header {
  color: #8e908f;
}

.mortality-results-settings-page {
  label {
    font-family: "DINNextLTPro-Regular";
  }
}

.age-group-line-between {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    border-right: 2px dashed #d8d8d8;
    height: calc(100% - 15px);
    left: 148px;
    top: 8px;
  }
}
</style>
